@charset "UTF-8";

// ==================================================
//  @use
//  mixin: @include index.sp {~}
//  color: color.$write-main
// ==================================================
@use "mixin" as mixin;
@use "color" as color;

// ==================================================
//  Page Service
// ==================================================
.page-service {
  // page content content width
  &.pageContent_contentWidth {
    @include mixin.sp {
      max-width: none;
    }
  }

  // page content title
  & > .pageContent_title {
    @include mixin.sp {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  // service
  .service {
    display: block;
    width: 100%;
    padding: 0 60px;

    @include mixin.sp {
      padding: 0 30px;
    }

    & > :first-child {
      margin-top: 0;
    }

    & > :last-child {
      margin-bottom: 0;
    }

    &_h2 {
      font-size: 2.4rem;
      font-weight: 500;
      margin: 40px 0 20px;

      @include mixin.sp {
        font-size: 1.7rem;
        margin: 31px 0 21px;
      }
    }

    &_h3 {
      font-size: 2.2rem;
      font-weight: 500;
      margin: 40px 0 20px;

      @include mixin.sp {
        font-size: 1.5rem;
        margin: 31px 0 21px;
      }
    }

    &_paragraph {
      margin: 16px 0 0;
      letter-spacing: 0.05em;

      @include mixin.sp {
        margin-top: 13px;
        letter-spacing: 0;
      }
    }

    &_list {
      margin: 32px 0 0;
      padding: 0 0 0 1.2em;
      list-style: disc;
      letter-spacing: 0.05em;

      @include mixin.sp {
        margin-top: 20px;
        letter-spacing: 0;
      }

      & > li {
        position: relative;
        margin-top: 10px;

        & > ul {
          margin: 20px 0 0;
          padding: 0 0 0 1.2em;
          list-style: disc;

          & > li {
            margin-top: 10px;
          }
        }
      }
    }

    &_image {
      margin: 30px 0 0;

      @include mixin.sp {
        margin-top: 20px;
      }

      & > img {
        display: block;
        width: 100%;
      }

      figcaption {
        font-size: 1.6rem;
        opacity: 0.8;
        margin-top: 3px;

        @include mixin.sp {
          font-size: 1.4rem;
          margin-top: 2px;
        }
      }
    }

    &_cta {
      display: block;
      width: 340px;
      padding: 20px 20px 19px;
      font-size: 1.7rem;
      font-weight: 500;
      text-align: center;
      text-decoration: none;
      color: color.$site-write;
      background-color: color.$site-cta;
      border: 1px solid color.$site-cta;
      border-radius: 100px;
      z-index: 80;
      margin: 40px auto 0;
      transition: color 0.3s ease, background-color 0.3s ease;

      @include mixin.sp {
        width: calc(100% - 30px);
        max-width: 295px;
        padding: 16px;
        font-size: 1.4rem;
        margin-top: 30px;
      }

      @include mixin.hover {
        color: color.$site-cta;
        background-color: color.$site-write;
      }
    }
  }
}
